export const homeAffiliate = {
  mainTitle: "RECOMMENDED LOCAL BUSINESS",
  business: [
    {
      businessLink: "https://linaillab.co.uk/",
      businessName: "LI Nail Lab",
      businessDescription:
        "Nail salon in Hednesford, offering great Manicure and Pedicure services.",
      businessImage: "linaillab.png",
      imageDescription: "...",
    },
    {
      businessLink:
        "https://instagram.com/beauties_permanent_makeup?igshid=MzMyNGUyNmU2YQ==",
      businessName: "BEAUTIES PERMANENT MAKE UP",
      businessDescription:
        "Qualified and Experienced Permanent Make up Artist.",
      businessImage: "BeautyES.png",
      imageDescription: "...",
    },
    {
      businessLink: "https://www.facebook.com/profile.php?id=100053487232257 ",
      businessName: "K.K. VISION",
      businessDescription: "Photographer and Videographer for any occasion.",
      businessImage: "vision.png",
      imageDescription: "...",
    },
       {
    businessLink: "https://relaxandexplore.notjusttravel.com/ ",
    businessName: "RELAX & EXPLORE - NOT JUST TRAVEL",
    businessDescription: "Personal Travel Consultancy",
    businessImage: "NJTRelax.png" ,
    imageDescription: "...",
    },
    {
    businessLink: "https://notjustcruise.co.uk/tc/relaxandexplore/ ",
    businessName: "RELAX & EXPLORE - NOT JUST CRUISE",
    businessDescription: "Personal Cruise Specialists",
    businessImage: "NJTCruise.png" ,
    imageDescription: "...",  
    },
  
  ],
}