export const services = {
  servicesPageMainTitle: "TREATMENT MENU",
  servicesPageLearnMoreButtonText: "READ MORE",
  servicesPageButtonText: "BOOK NOW",
  items: [
    {
      mainImage: "PHOTO-2023-11-05-14-05-44.jpg",
      mainImageDescription: "...",
      serviceTitle: "Gentle Back Massage",
      serviceId: "RelaxingBackMassage",
      servicePriceText: "From",
      servicePrice: "36",
      serviceCurrency: "£",
      serviceDescriptions: {
        image: "PHOTO-2023-11-05-14-05-46.jpg",
        imageDescription: "...",
        serviceMainDescription:
          "A gentle back massage is a therapeutic technique that aims to relieve tension and promote relaxation in the back muscles. It involves applying gentle pressure and kneading motions to the muscles using various techniques. The soothing strokes and movements help improve blood circulation, release muscle tension, reduce stress, and enhance overall well-being. It's a great way to unwind and rejuvenate, especially after a long day.",
        serviceSecDescriptions: [
          // {
          //   title: "",
          //   paragraph: "",
          // },
        ],
      },
      servicesPricesList: [
        {
          mins: "30 Mins -",
          price: "36",
        },
        {
          mins: "45 Mins -",
          price: "48",
        },
        {
          mins: "60 Mins -",
          price: "60",
        },
      ],
    },
    {
      mainImage: "439a14f3-e4d7-4e16-bc49-58e8ea9cfc02.JPG",
      mainImageDescription: "...",
      serviceTitle: "Deep Tissue Back Massage",
      serviceId: "DeepTissueBackMassage",
      servicePriceText: "From",
      servicePrice: "60",
      serviceCurrency: "£",
      serviceDescriptions: {
        image: "6afcdfec-f38e-46b0-af77-a86518ccd09a.JPG",
        imageDescription: "...",
        serviceMainDescription:
          "A deep tissue back massage is a therapeutic technique that involves applying firm pressure and slow strokes to reach the deeper layers of muscles in the back. It aims to release chronic muscle tension, improve posture, increase range of motion, and promote relaxation. This type of massage can be intense and may cause temporary discomfort, but it provides long-lasting relief from muscle tightness and chronic pain. It is beneficial for those seeking a more targeted and therapeutic approach to address specific issues in the back muscles.",
        serviceSecDescriptions: [
          // {
          //   title: "",
          //   paragraph: "",
          // },
        ],
      },
      servicesPricesList: [
               {
          mins: "60 Mins -",
          price: "60",
        },
      ],
    },
    {
      mainImage: "9d5f5a6b-9c7a-4ac2-8a7e-bc4f6e9030e2.JPG",
      mainImageDescription: "...",
      serviceTitle: "Full Body                    Massage",
      serviceId: "FullBodyMassage",
      servicePriceText: "From",
      servicePrice: "60",
      serviceCurrency: "£",
      serviceDescriptions: {
        image: "a030e70a-c62e-40e9-880b-d5db7cc1c422.JPG",
        imageDescription: "...",
        serviceMainDescription:
          "A relaxing full body massage is a therapeutic technique that encompasses the entire body. It involves applying various techniques such as kneading, stroking, and stretching to promote relaxation, relieve muscle tension, and improve overall well-being. This includes face, head, neck, back, shoulders, arms, abdomen, legs, and feet, providing a comprehensive and soothing experience. A full body massage helps to improve blood circulation, release muscle tension, reduce stress, and promote a sense of deep relaxation. It is an excellent choice for those seeking a complete rejuvenation and a blissful escape from daily stressors.",
        serviceSecDescriptions: [
          // {
          //   title: "",
          //   paragraph: "",
          // },
        ],
      },
      servicesPricesList: [
        {
          mins: "60 Mins -",
          price: "60",
        },
        {
          mins: "90 Mins -",
          price: "90",
        },
      ],
    },
    {
      mainImage: "4e14b335-39a6-4dcb-93f8-444e7df50d40.JPG",
      mainImageDescription: "...",
      serviceTitle: "Deep Tissue Full Body Massage",
      serviceId: "DeepTissueFullBodyMassage",
      servicePriceText: "From",
      servicePrice: "78",
      serviceCurrency: "£",
      serviceDescriptions: {
        image: "bfeea4d1-eb64-4ed4-9ba1-a34d582a5f14.JPG",
        imageDescription: "...",
        serviceMainDescription:
          "A deep tissue full body massage is a therapeutic technique that involves applying firm pressure and slow strokes to reach the deeper layers of muscles throughout the entire body. It aims to release chronic muscle tension and knots, improve posture, increase range of motion, and promote relaxation. This type of massage can be intense and may cause temporary discomfort, but it provides long-lasting relief from muscle tightness and chronic pain. It is beneficial for those seeking a more comprehensive and therapeutic approach to address specific issues in multiple areas of the body.",
        serviceSecDescriptions: [
          // {
          //   title: "",
          //   paragraph: "",
          // },
        ],
      },
      servicesPricesList: [
        {
          mins: "60 Mins -",
          price: "78",
        },
      ],
    },

    {
      mainImage: "61c08afb-3049-4b53-b840-803e60347f61.JPG",
      mainImageDescription: "...",
      serviceTitle: "Neck & Shoulders Massage",
      serviceId: "NeckShouldersMassage",
      servicePriceText: "From",
      servicePrice: "54",
      serviceCurrency: "£",
      serviceDescriptions: {
        image: "fea454cb-52c4-465b-bc70-fec6d202733a.JPG",
        imageDescription: "...",
        serviceMainDescription:
          "A neck and shoulders tension relief massage is a therapeutic technique specifically designed to target and alleviate tension in the neck and shoulder muscles. Focused pressure kneading motions are applied to these areas, using techniques like deep tissue massage or trigger point therapy. This helps to release muscle tension, improve blood circulation, and reduce pain and stiffness in the neck and shoulders. The massage may also include stretching and gentle movements to increase flexibility and promote relaxation. It's an effective way to relieve tension and promote overall comfort and well-being in the upper body.",
        serviceSecDescriptions: [
          // {
          //   title: "",
          //   paragraph: "",
          // },
        ],
      },
      servicesPricesList: [
        {
          mins: "45 Mins -",
          price: "54",
        },
      ],
    },
    {
      mainImage: "afb86c69-f4cf-4afa-a497-c20b9c0b662d.JPG",
      mainImageDescription: "...",
      serviceTitle: "Legs Massage",
      serviceId: "LegsMassage",
      servicePriceText: "From",
      servicePrice: "60",
      serviceCurrency: "£",
      serviceDescriptions: {
        image: "3e1be367-1db7-4d18-865f-0fb4421c1804.JPG",
        imageDescription: "...",
        serviceMainDescription:
          "A legs massage is a therapeutic technique that focuses on the muscles of the legs. It involves applying pressure and using various techniques such as kneading, stroking, and stretching to promote relaxation, improve blood circulation, and relieve muscle tension. Specific areas of the legs may be targeted, such as the calves or thighs, to address any specific concerns or areas of discomfort. A legs massage can help reduce muscle soreness, increase flexibility, and provide a sense of overall well-being. It is a great option for those who spend long hours on their feet or engage in physical activities.",
        serviceSecDescriptions: [
          // {
          //   title: "",
          //   paragraph: "",
          // },
        ],
      },
      servicesPricesList: [
        {
          mins: "60 Mins - ",
          price: "60",
        },
      ],
    },
    {
      mainImage: "d1b39fcf-4400-4418-acb2-b7de8c9a5931.JPG",
      mainImageDescription: "...",
      serviceTitle: "Warm Rattan Massage",
      serviceId: "wormRattanMassage",
      servicePriceText: "From",
      servicePrice: "72",
      serviceCurrency: "£",
      serviceDescriptions: {
        image: "5049ffef-257c-42f2-95a9-66a223c1a86f.JPG",
        imageDescription: "...",
        serviceMainDescription:
          "A warm rattan massage is a therapeutic technique that utilises warm rattan sticks to target the tense muscles. The rattan sticks, which are smooth and heated, are gently rolled and pressed along the back, legs or arms providing a soothing and deep massage experience. The warmth from the rattan sticks helps to relax the muscles, improve blood circulation, and promote a sense of relaxation and well-being. The unique texture and shape of the rattan sticks allow for precise and effective manipulation of the muscles, releasing tension and promoting a feeling of rejuvenation. It is a luxurious and invigorating treatment.",
        serviceSecDescriptions: [
          // {
          //   title: "",
          //   paragraph: "",
          // },
        ],
      },
      servicesPricesList: [
               {
          mins: "60 Mins -",
          price: "72",
        },
        // {
        //   mins: "",
        //   price: "",
        // },
      ],
    },
    
    {
      mainImage: "hotStoneMassage.png",
      mainImageDescription: "...",
      serviceTitle: "Hot Stone Massage",
      serviceId: "hotStoneMassage",
      servicePriceText: "From",
      servicePrice: "72",
      serviceCurrency: "£",
      serviceDescriptions: {
        image: "3821a820-c606-45bd-bc6c-59bd40d5c972.JPG",
        imageDescription: "...",
        serviceMainDescription:
          "A hot stone massage is a therapeutic technique that combines the use of heated stones with traditional massage strokes to target the tense muscles. Smooth, heated stones are placed on specific areas of the body to warm and relax the muscles. Applying gentle pressure and gliding motion heated stones are moved along the muscles to release tension and promote deep relaxation. The heat from the stones helps to improve blood circulation, soothe sore muscles, and enhance the overall massage experience. It is a soothing and rejuvenating treatment.",
        serviceSecDescriptions: [
          // {
          //   title: "",
          //   paragraph: "",
          // },
        ],
      },
      servicesPricesList: [
      
        {
          mins: "60 Mins -",
          price: "72",
        },
        // {
        //   mins: "",
        //   price: "",
        // },
      ],
    },
    
        {
      mainImage: "45050858-229d-4d6d-a732-56edddbd765e.JPG",
      mainImageDescription: "...",
      serviceTitle: "Lymphatic Drainage Massage",
      serviceId: "LymphaticDrainageMassage",
      servicePriceText: "From",
      servicePrice: "72",
      serviceCurrency: "£",
      serviceDescriptions: {
        image: "e9c6328e-ada8-4a0e-bf4f-e53ef5e43fd5.JPG",
        imageDescription: "...",
        serviceMainDescription:
          "A lymphatic drainage massage is a gentle and rhythmic technique that aims to stimulate the lymphatic system, which helps remove toxins and waste from the body. Light pressure and specific hand movements are used to encourage the flow of lymph fluid throughout the body. This can help reduce swelling, boost the immune system, improve circulation, and promote overall detoxification and well-being. Lymphatic drainage massage is often used to address conditions such as lymphedema, post-surgical swelling, and immune system disorders. It is a gentle and relaxing massage that supports the body's natural detoxification processes.",
        serviceSecDescriptions: [
          // {
          //   title: "",
          //   paragraph: "",
          // },
        ],
      },
      servicesPricesList: [
        {
          mins: "60 Mins -",
          price: "72",
        },
      ],
    },
    {
      mainImage: "2ee3f39a-aef9-4c5c-ab19-8f3065fc9ee8.JPG",
      mainImageDescription: "...",
      serviceTitle: "Sports Massage",
      serviceId: "SportsMassage",
      servicePriceText: "From",
      servicePrice: "42",
      serviceCurrency: "£",
      serviceDescriptions: {
        image: "PHOTO-2023-11-05-21-47-14.jpg",
        imageDescription: "...",
        serviceMainDescription:
          "A sports massage is a therapeutic technique specifically designed for athletes and individuals engaged in physical activities. It focuses on preventing and treating injuries, improving performance, and aiding in recovery. Combination of techniques is used such as deep tissue massage, stretching, and joint mobilisation to target specific muscles and areas of the body that are commonly affected by sports-related stress and tension. Sports massage helps to enhance flexibility, reduce muscle soreness, increase blood flow, and promote overall athletic performance and well-being. It is a valuable tool for athletes and active individuals looking to optimise their training and recovery.",
        serviceSecDescriptions: [
          // {
          //   title: "",
          //   paragraph: "",
          // },
        ],
      },
      servicesPricesList: [
        {
          mins: "30 Mins -",
          price: "42",
        },
        {
          mins: "60 Mins -",
          price: "66",
        },
      ],
    },
    {
      mainImage: "fireCupping.png",
      mainImageDescription: "...",
      serviceTitle: "Fire Cupping",
      serviceId: "fireCupping",
      servicePriceText: "From",
      servicePrice: "48",
      serviceCurrency: "£",
      serviceDescriptions: {
        image: "93c595e3-1ff1-4f9f-ad4c-0550a6b9af6a.JPG",
        imageDescription: "...",
        serviceMainDescription:
          "Fire cupping is a therapeutic treatment that involves placing glass cups on the skin and creating a vacuum by briefly heating the air inside the cup. As the air cools, it creates suction, drawing the skin and underlying tissues into the cup. This technique is believed to help improve blood circulation, release muscle tension, and promote healing. The cups are typically left in place for 15 to 20 minutes. This is a more gentle aproach to release muscle tension. Highly recommended for individuals that have never had a deep tissue massage or had a long break since their last deep tissue massage session.  Fire cupping can leave temporary marks on the skin, but it is generally a safe and effective treatment for various conditions, including muscle pain and inflammation.",
        serviceSecDescriptions: [
          {
            title: "",
            paragraph: "",
          },
          // {
          //   title: "",
          //   paragraph: "",
          // },
        ],
      },
      servicesPricesList: [
        {
          mins: "One Area -",
          price: "48",
        },
        {
          mins: "Two Areas -",
          price: "55",
        },
        {
          mins: "Three Areas -",
          price: "60",
        },
        // {
        //   mins: "",
        //   price: "",
        // },
      ],
    },
    {
      mainImage: "FCup1.jpg",
      mainImageDescription: "...",
      serviceTitle: "Fire Cupping & Tension Relief",
      serviceId: "FireCupTensionRelief",
      servicePriceText: "",
      servicePrice: "55",
      serviceCurrency: "£",
      serviceDescriptions: {
        image: "Fcup2.jpg",
        imageDescription: "...",
        serviceMainDescription:
        "Combination of Fire Cupping Session plus 15 min Massage after to relieve some of the tension. This therapeutic treatment is a great choice if you feel that cupping alone is not quite enough.",
      serviceSecDescriptions: [
          // {
          //   title: "",
          //   paragraph: "",
          // },

        ],
      },
      servicesPricesList: [
        {
          mins: "60 Mins -",
          price: "55",
        },
      ],
    },
    {
      mainImage: "903db8dd-265b-4a86-95c1-cf12f447d1fa.JPG",
      mainImageDescription: "...",
      serviceTitle: "Indian Head Massage",
      serviceId: "IndianHeadMassage",
      servicePriceText: "From",
      servicePrice: "36",
      serviceCurrency: "£",
      serviceDescriptions: {
        image: "c6a0c3fb-df65-447b-8103-866aafc8d61a.JPG",
        imageDescription: "...",
        serviceMainDescription:
          "Indian head massage, also known as champissage, is a therapeutic technique that focuses on the face, head, neck, and shoulders. It involves applying firm but gentle pressure to these areas, using a combination of techniques like kneading, tapping, and circular motions. The massage helps to relieve tension, improve blood circulation, and promote relaxation. It can also stimulate hair growth and relieve headaches.",
        serviceSecDescriptions: [
          // {
          //   title: "",
          //   paragraph: "",
          // },
        ],
      },
      servicesPricesList: [
        {
          mins: "30 Mins -",
          price: "36",
        },
        {
          mins: "60 Mins -",
          price: "72",
        },
      ],
    },
    {
      mainImage: "dbc03a4e-effa-4458-af08-cda3daf7dab4.JPG",
      mainImageDescription: "...",
      serviceTitle: "Signature Mini Facial",
      serviceId: "MiniFacial",
      servicePriceText: "",
      servicePrice: "36",
      serviceCurrency: "£",
      serviceDescriptions: {
        image: "d369e93e-9f31-44e7-bd4a-6909bad7f0f0.JPG",
        imageDescription: "...",
        serviceMainDescription:
          "Relax Massage Studio Signature Mini Facial is a rejuvenating skincare treatment that focuses on cleansing, hydrating, and revitalising the skin. The treatment typically begins with a gentle double clense and gentle exfoliation to prepare the skin for further steps. This is followed by the application of a hydrating face mask. The mask is left on for a specified time to allow for deep penetration and nourishment. Finally, the facial concludes with a hydrating step, where a moisturiser or serum is applied to replenish and lock in moisture, leaving the skin refreshed and glowing. The signature mini facial is a quick and effective way to give the skin a boost and promote a healthy complexion.",
        serviceSecDescriptions: [
          // {
          //   title: "",
          //   paragraph: "",
          // },
        ],
      },
      servicesPricesList: [
        {
          mins: "30 Mins -",
          price: "36",
        },
      ],
    },
    {
      mainImage: "433ff2de-74ae-4a01-8cb2-e63a545c7f22.JPG",
      mainImageDescription: "...",
      serviceTitle: "Signature 'Sticks & Stones' Massage",
      serviceId: "SticksStonesFullBodyMassage",
      servicePriceText: "",
      servicePrice: "84",
      serviceCurrency: "£",
      serviceDescriptions: {
        image: "bdb055bd-5393-442a-8a93-b39ef1b04928.JPG",
        imageDescription: "...",
        serviceMainDescription:
          "Relax Massage Studio Signature 'Sticks & Stones' massage is a unique and indulgent spa experience that combines the use of warm rattan sticks and smooth stones to provide a deeply relaxing and therapeutic massage.",
        serviceSecDescriptions: [
          // {
          //   title: "",
          //   paragraph: "",
          // },
        ],
      },
      servicesPricesList: [
        {
          mins: "60 Mins -",
          price: "84",
        },
        // {
        //   mins: "",
        //   price: "",
        // },
      ],
    },
    {
      mainImage: "c0d3f324-d5bb-47be-a348-edcd35dc8ad7.JPG",
      mainImageDescription: "...",
      serviceTitle: "Signature Hands & Feet Treatment",
      serviceId: "WonderfulHandsFeetMassage",
      servicePriceText: "",
      servicePrice: "60",
      serviceCurrency: "£",
      serviceDescriptions: {
        image: "959a7b44-f37f-4d3f-8a4e-8be7dff81233.JPG",
        imageDescription: "...",
        serviceMainDescription:
          "Relax Massage Studio Signature Hands & Feet Treatment is a luxurious spa experience.A gentle exfoliation is performed to remove dead skin cells and promote smoothness. This is followed by a hydrating treatment to nourish and moisturise, refresh and revitalise the skin.The massage gentle strokes and various techniques are used to relieve tension and promote relaxation in the hands and feet. This helps to improve blood circulation and reduce any stiffness or discomfort.",

        serviceSecDescriptions: [
                    // {
          //   title: "",
          //   paragraph: "",
          // },
        ],
      },
      servicesPricesList: [
        {
          mins: "60 Mins -",
          price: "60",
        },
        // {
        //   mins: "",
        //   price: "",
        // },
      ],
    },
    {
      mainImage: "SMFHM1.png",
      mainImageDescription: "...",
      serviceTitle: "Signature Mini Facial with Head Massage",
      serviceId: "SignatureMiniFacialHeadMassage",
      servicePriceText: "",
      servicePrice: "70",
      serviceCurrency: "£",
      serviceDescriptions: {
        image: "SMFHM2.jpg",
        imageDescription: "...",
        serviceMainDescription:
          "Relax Massage Studio Signature Mini Facial with Head Massage is a luxurious spa experience. Mini Facial is a rejuvenating skincare treatment that focuses on cleansing, hydrating, and revitalising the skin. Gentle double clense, skin exfoliation and a hydrating face mask. Face massage with moisturiser or serum to leave skin refreshed and glowing. Head, neck, and shoulders massage with firm but gentle pressure this treatment helps to relieve tension, improve blood circulation, and promote relaxation. It can also relieve headaches.",
        serviceSecDescriptions: [
          // {
          //   title: "",
          //   paragraph: "",
          // },

        ],
      },
      servicesPricesList: [
        {
          mins: "60 Mins -",
          price: "70",
        },
      ],
    },
    {
      mainImage: "32f44f5c-652b-404c-b384-95bfe7d9b50f.JPG",
      mainImageDescription: "...",
      serviceTitle: "Signature Head & Feet Treatment",
      serviceId: "FabulousFeetHeadMassage",
      servicePriceText: "",
      servicePrice: "60",
      serviceCurrency: "£",
      serviceDescriptions: {
        image: "b8d206e9-63ff-4020-ab1c-68ee7b18ea1b.JPG",
        imageDescription: "...",
        serviceMainDescription:
          "Relax Massage Studio Signature Head & Feet Treatment is a luxurious spa experience that begins with a relaxing face and head massage. This massage focuses on relieving tension and promoting relaxation in the facial and scalp muscles. It helps improve blood circulation, reduce stress and rejuvenate the mind. After the head massage, the treatment moves on to the feet. A gentle exfoliation is performed to remove dead skin cells and promote smoothness. This is followed by a hydrating foot treatment to nourish and moisturise the skin, leaving the feet feeling refreshed and revitalised.",
        serviceSecDescriptions: [
          {
            title: "",
            paragraph:
              "After the head massage, the treatment moves on to the feet. A gentle exfoliation is performed to remove dead skin cells and promote smoothness. This is followed by a hydrating foot treatment to nourish and moisturise the skin, leaving the feet feeling refreshed and revitalised.",
          },
          // {
          //   title: "",
          //   paragraph: "",
          // },
        ],
      },
      servicesPricesList: [
        {
          mins: "60 Mins -",
          price: "60",
        },
      ],
    },
    {
      mainImage: "SignatureBackFacial1.jpg",
      mainImageDescription: "...",
      serviceTitle: "Signature Back 'Facial'",
      serviceId: "SignatureBackFacial",
      servicePriceText: "",
      servicePrice: "65",
      serviceCurrency: "£",
      serviceDescriptions: {
        image: "SignatureBackFacial2.jpg",
        imageDescription: "...",
        serviceMainDescription:
          "Signature Back Facial that not only deeply cleanses and rejuvenates the skin on the back but also provides a relaxing experience.",
serviceSecDescriptions: [
  {
    title: "",
    paragraph:
      "Back Facial is a treatment that focuses on cleansing, hydrating, and revitalising the skin on the back. Treatment begins with a double cleanse and exfoliation. This is followed by the application of a hydrating mask. The mask is left on for a specified time to allow for deep penetration and nourishment. Hands and arms massage is performed while the mask is working its magic. Finally, the treatment concludes with a relaxing, hydrating back massage, where a moisturiser or serum is applied to replenish and lock in moisture, leaving the skin healthy and refreshed.", 
  },
             // {
          //   title: "",
          //   paragraph: "",
          // },

        ],
      },
      servicesPricesList: [
        {
          mins: "60 Mins -",
          price: "65",
        },
      ],
    },
    {
      mainImage: "Tu.jpg",
      mainImageDescription: "...",
      serviceTitle: "TuiNa & Moxi Treatment",
      serviceId: "TuiNa&Moxi",
      servicePriceText: "",
      servicePrice: "65",
      serviceCurrency: "£",
      serviceDescriptions: {
        image: "Tu2.jpg",
        imageDescription: "...",
        serviceMainDescription:
          "TuiNa & Moxi Treatment involves using TuiNa massage techniques, which focus on acupressure points and meridians to promote the flow of Qi (energy) in the body, along with moxibustion, which involves burning moxa to stimulate acupuncture points. This combined treatment aims to relieve muscle tension, improve circulation, promote relaxation, and balance the body's energy flow for overall wellness.",
serviceSecDescriptions: [
  {
    title: "",
    paragraph:
      "TuiNa & Moxi Treatment is best used to treat conditions such as:",
  },
  {
    title: "",
    paragraph:
      "Muscle tension, Pain Management, Stress and Anxiety, Digestive Disorders, Menstrual Cramps", 
  },
          // {
          //   title: "",
          //   paragraph: "",
          // },

        ],
      },
      servicesPricesList: [
        {
          mins: "60 Mins -",
          price: "65",
        },
      ],
    },
    {
      mainImage: "Psychosomatic1.png",
      mainImageDescription: "...",
      serviceTitle: "Psychosomatic Massage",
      serviceId: "FPsyhosomatic",
      servicePriceText: "From",
      servicePrice: "60",
      serviceCurrency: "£",
      serviceDescriptions: {
        image: "Psychosomatic2.png",
        imageDescription: "...",
        serviceMainDescription:
          "Psychosomatic treatment is a holistic approach that integrates traditional massage techniques with elements of psychology to address the intricate mind-body connection. This therapeutic session is dedicated to releasing emotional stress and tension that may be stored in various areas of the body, including the back, shoulders, neck, arms, legs, and feet. The pressure applied during the treatment can range from gentle strokes to slightly deeper pressure, personalised to suit the individual's specific needs. By concurrently addressing both the physical and emotional dimensions, psychosomatic treatment aims to facilitate relaxation, alleviate physical discomfort, and enhance overall well-being. The treatment protocol typically involves the client being fully undressed with minimal covering for modesty, allowing direct skin contact to optimise the effectiveness of the massage techniques in releasing emotional tension and stress. Clients are assured of utmost comfort and privacy throughout the session. To embark on this transformative journey, an initial consultation is essential and can be scheduled for a 30-minute meeting via the booking page. It is recommended to commit to a block of 3, 6, or 9 sessions to experience noticeable changes. Should you choose to book and pay for the treatment on the day of the session, the initial consultation fee will be waived. For optimal results, attending sessions on a weekly basis is advised.",
        serviceSecDescriptions: [
          // {
          //   title: "",
          //   paragraph: "",
          // },

        ],
      },
      servicesPricesList: [
        {
          mins: "Single Session",
          price: "60",
        },
        {
          mins: "Block of 3 Sessions",
          price: "165",
        },
        {
          mins: "Block of 6 Sessions",
          price: "318",
        },
        {
          mins: "Block of 9 Sessions",
          price: "450",
        },
      ],
    },          
    {
      mainImage: "1858171f-2016-482f-a9bf-4170b2a48ffb.JPG",
      mainImageDescription: "...",
      serviceTitle: "Paraffin Wax Hands Treatment",
      serviceId: "ParaffinWaxHandsTreatment",
      servicePriceText: "",
      servicePrice: "18",
      serviceCurrency: "£",
      serviceDescriptions: {
        image: "a64b86dd-2c75-4b77-bf32-9f613f4c8a81.JPG",
        imageDescription: "...",
        serviceMainDescription:
          "A paraffin wax hand treatment is a spa therapy that involves immersing the hands in warm, melted paraffin wax. Hands are dipped into the warm paraffin wax, allowing the wax to coat the hands completely. The hands are then covered with plastic and a towel and left to relax and absorb the heat for a few minutes. The heat from the wax helps to open up the pores, increase blood circulation, and moisturise the skin. After the wax is removed, the hands are left feeling soft, smooth, and rejuvenated. This treatment is particularly beneficial for dry, rough, or tired hands.",
        serviceSecDescriptions: [
          // {
          //   title: "",
          //   paragraph: "",
          // },
        ],
      },
      servicesPricesList: [
        {
          mins: "",
          price: "18",
        },
        // {
        //   mins: "",
        //   price: "",
        // },
      ],
    },
    {
      mainImage: "Reflex1.jpg",
      mainImageDescription: "...",
      serviceTitle: "Reflexology (Feet)",
      serviceId: "FeetReflexology",
      servicePriceText: "",
      servicePrice: "60",
      serviceCurrency: "£",
      serviceDescriptions: {
        image: "Reflex2.jpg",
        imageDescription: "...",
        serviceMainDescription:
          "Feet Reflexology uses a mix of massage techniques to target various points on the feet that correspond to vital body organs and allows feet to also feel relaxed and cared for. This treatment balances internal and external systems creating revitalising effect. Foot reflexology is a simple and non-invasive method to help bring the body to the right balance.",
        serviceSecDescriptions: [
          // {
          //   title: "",
          //   paragraph: "",
          // },

        ],
      },
      servicesPricesList: [
        {
          mins: "60 Mins -",
          price: "60",
        },
      ],
    },
    {
      mainImage: "99991752-89a5-4133-b5a1-aee99546937b.JPG",
      mainImageDescription: "...",
      serviceTitle: "Reiki",
      serviceId: "reikiHealing",
      servicePriceText: "",
      servicePrice: "60",
      serviceCurrency: "£",
      serviceDescriptions: {
        image: "eb898e44-3e3c-4cf1-b7b9-ae5d64808f85.JPG",
        imageDescription: "...",
        serviceMainDescription:
          "During this treatment, hands-on or hands-off approach is implemented to channel life force energy promoting relaxation, stress reduction, and overall well-being, helping to balance and restore the energy system.",
        serviceSecDescriptions: [
          // {
          //   title: "",
          //   paragraph: "",
          // },
        ],
      },
      servicesPricesList: [
        {
          mins: "45-60 Mins -",
          price: "60",
        },
      ],
    },
    
    {
      mainImage: "HomeVisits1.jpg",
      mainImageDescription: "...",
      serviceTitle: "Home Visit / Please Contact Directly to Book",
      serviceId: "HomeVisit",
      servicePriceText: "",
      servicePrice: "Varies",
      serviceCurrency: "£",
      serviceDescriptions: {
        image: "HomeVisits2.jpg",
        imageDescription: "...",
        serviceMainDescription:
          "Home Visit can be arranged in some circumstances. Please note no discount can be applied for Home Visit. This service has to be booked in advance and has to be confirmed verbally or in writing to be valid. Final price will be confirmed once the treatment and location has been discussed. Please Whatsapp me on 01543 524924.",        serviceSecDescriptions: [
          // {
          //   title: "",
          //   paragraph: "",
          // },

        ],
      },
      servicesPricesList: [
        {
          mins: "Please contact me",
          price: "TBC",
        },
      ],
    },
    
   
    // {
    //   mainImage: "exfoliation.png",
    //   mainImageDescription: "...",
    //   serviceTitle: "Back Massage Mixed Techniques",
    //   serviceId: "BackMassageMixedTechniques",
    //   servicePriceText: "From",
    //   servicePrice: "50",
    //   serviceCurrency: "£",
    //   serviceDescriptions: {
    //     image: "exfoliation.png",
    //     imageDescription: "...",
    //     serviceMainDescription:
    //       "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Necessitatibus, ipsum molestiae.",
    //     serviceSecDescriptions: [
    //       // {
    //       //   title: "",
    //       //   paragraph: "",
    //       // },
    //     ],
    //   },
    //   servicesPricesList: [
    //     // {
    //     //   mins: "",
    //     //   price: "",
    //     // },
    //   ],
    // },

    // {
    //   mainImage: "exfoliation.png",
    //   mainImageDescription: "...",
    //   serviceTitle: "Herbal Stamp Full Body Massage",
    //   serviceId: "HerbalStampFullBodyMassage",
    //   servicePriceText: "From",
    //   servicePrice: "65",
    //   serviceCurrency: "£",
    //   serviceDescriptions: {
    //     image: "exfoliation.png",
    //     imageDescription: "...",
    //     serviceMainDescription:
    //       "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Necessitatibus, ipsum molestiae.",
    //     serviceSecDescriptions: [
    //       // {
    //       //   title: "",
    //       //   paragraph: "",
    //       // },
    //     ],
    //   },
    //  servicesPricesList: [
    //    {
    //     mins: "",
    //     price: "",
    //    },
    //  ],
    // },
    // {
    //   mainImage: "footReflexology.png",
    //   mainImageDescription: "...",
    //   serviceTitle: "Reflexology (Feet)",
    //   serviceId: "ReflexologyFeet",
    //   servicePriceText: "From",
    //   servicePrice: "50",
    //   serviceCurrency: "£",
    //   serviceDescriptions: {
    //     image: "exfoliation.png",
    //     imageDescription: "...",
    //     serviceMainDescription:
    //       "Foot reflexology is a type of reflexology that focuses on applying pressure to specific points on the feet to improve overall health and well-being.",
    //     serviceSecDescriptions: [
    //       {
    //         title: "Benefits of Foot Reflexology",
    //         paragraph:
    //           "Foot reflexology offers a multitude of benefits, including stress reduction, enhanced relaxation, improved circulation, strengthened immunity, pain relief, and an overall sense of well-being.",
    //       },
    //     ],
    //   },
    //  servicesPricesList: [
    //    {
    //     mins: "",
    //     price: "",
    //    },
    //  ],
    // },
    // {
    //   mainImage: "exfoliation.png",
    //   mainImageDescription: "...",
    //   serviceTitle: "Pregnancy Full Body",
    //   serviceId: "PregnancyFullBody",
    //   servicePriceText: "From",
    //   servicePrice: "50",
    //   serviceCurrency: "£",
    //   serviceDescriptions: {
    //     image: "exfoliation.png",
    //     imageDescription: "...",
    //     serviceMainDescription:
    //       "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Necessitatibus, ipsum molestiae.",
    //     serviceSecDescriptions: [
    //       // {
    //       //   title: "",
    //       //   paragraph: "",
    //       // },
    //     ],
    //   },
    //  servicesPricesList: [
    //    {
    //     mins: "",
    //     price: "",
    //    },
    //  ],
    // },
    // {
    //   mainImage: "exfoliation.png",
    //   mainImageDescription: "...",
    //   serviceTitle: "Rejuvenating Body Exfoliation",
    //   serviceId: "RejuvenatingBodyExfoliation",
    //   servicePriceText: "From",
    //   servicePrice: "60",
    //   serviceCurrency: "£",
    //   serviceDescriptions: {
    //     image: "exfoliation.png",
    //     imageDescription: "...",
    //     serviceMainDescription:
    //       "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Necessitatibus, ipsum molestiae.",
    //     serviceSecDescriptions: [
    //       // {
    //       //   title: "",
    //       //   paragraph: "",
    //       // },
    //     ],
    //   },
    //  servicesPricesList: [
    //    {
    //     mins: "",
    //     price: "",
    //    },
    //  ],
    // },
    // {
    //   mainImage: "",
    //   mainImageDescription: "...",
    //   serviceTitle: "",
    //   serviceId: "",
    //   servicePriceText: "",
    //   servicePrice: "",
    //   serviceCurrency: "£",
    //   serviceDescriptions: {
    //     image: "",
    //     imageDescription: "...",
    //     serviceMainDescription:
    //       "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Necessitatibus, ipsum molestiae.",
    //     serviceSecDescriptions: [
    //       // {
    //       //   title: "",
    //       //   paragraph: "",
    //       // },
    //     ],
    //   },
    //  servicesPricesList: [
    //    {
    //     mins: "",
    //     price: "",
    //    },
    //  ],
    // },
  ],
};
