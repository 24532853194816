export const contactInfo = {
  contactText: "CONTACT ME",
  whereToFindMeText: "WHERE TO FIND ME",
  mainBookAppointmentButton: "BOOK NOW",
  phone: "tel:01543524924",
  phoneDisplay: "01543 524 924",
  whatsapp: "https://wa.me/1543524924",
  whatsappDisplay: "WhatsApp",
  email: "mailto:relax.massage.studio@icloud.com",
  emailDisplay: "Email",
  facebook: "https://www.facebook.com/profile.php?id=100084589461441",
  facebookDisplay: "Facebook",
  instagram: "https://www.instagram.com/relax.massage.studio.cannock/",
  instagramDisplay: "Instagram",
  youtube: "https://www.youtube.com/@relaxmassagestudio/videos",
  youtubeDisplay: "YouTube",
  address:
    "https://www.google.com/maps/place/34+Haymaker+Way,+Wimblebury,+Cannock+WS12+0FU/@52.7027386,-1.9753925,17z/data=!3m1!4b1!4m6!3m5!1s0x487a0abe80d983ff:0x7aa752da9dc03ab6!8m2!3d52.7027386!4d-1.9753925!16s%2Fg%2F11c5dctl26?entry=ttu",
  addressName: "RELAX MASSAGE STUDIO CANNOCK",
  addressDisplay: "34 Haymaker Way, Wimblebury, Cannock",
  addressDisplayPostcode: "WS12 0FU",
  paymentOptions: "PAYMENT OPTION: Cash, Card and ApplePay",
};
