export const aboutMe = {
  title: "ABOUT ME",
  descriptionOne:
    "Welcome to the Award Winning Relax Massage Studio Cannock, where your well-being is my top priority. I'm Aneta, a qualified and experienced massage therapist, and I'm here to help you unwind and find relief from the stresses of everyday life. Since opening my doors in January 2023, I have poured my heart and soul into creating a space that promotes relaxation and healing. With a passion for soft tissue manipulation and therapeutic pain relief, I am dedicated to providing personalised massages that cater to your specific needs.",
  imageOne: "about-me.jpg",
  imageOneDescription: "...",
  list: [
    {
      paragraph:
        "At my studio, I take a holistic approach to well-being. Each massage is carefully tailored to address your unique preferences and concerns, ensuring an unparalleled experience. My heated massage table, high quality skin products and serene ambiance create the perfect environment for relaxation.",
      image: "PHOTO-2023-11-05-21-56-09.jpg",
      imageDescription: "...",
    },
    {
      paragraph:
        "Relax Massage Studio was recognised as the runner-up winner in the Best Massage Therapist category at the UK Beauty Awards 2023. 2nd place as Home Salon of the Year at the SOS Beauty Awards 2024 and Holistic Therapist of the Year 2024 in 4th place in the whole of UK and Best Holistic Therapist 2024 Finalist at National Beauty Awards. This accolade is a testament to the dedication and commitment I bring to my craft.",
      image: "PHOTO-2023-11-03-20-02-25.jpg",
      imageDescription: "...",
    },
    {
      paragraph:
        "So, if you're looking for a massage experience that goes above and beyond, look no further than Relax Massage Studio Cannock. Book your appointment today and let me help you relax, unwind, and find your inner peace.",
      image: "about-me-massage.jpg",
      imageDescription: "...",
    },
    // {
    //   paragraph: "",
    //   image: "",
    //   imageDescription: "...",
    // },
  ],
  memberSectionText: "Member of ",
  memberSectionList: [
    { memberSectionImage: "fht.png", memberSectionImageDescription: "..." },
    { memberSectionImage: "chp.png", memberSectionImageDescription: "..." },
    { memberSectionImage: "FSB.webp", memberSectionImageDescription: "..." },
    //{ memberSectionImage: "", memberSectionImageDescription: "..." },
  ],
};
