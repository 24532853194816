export const homeOffers = {
  titleImage: "special-offers.png",
  titleImageDescription: "special offer",
  offerTitle: "SPECIAL OFFER",

  percentageOff: "£100 off ",
  percentageSign: "your next holiday ",
  percentageText: "booked with Just Relax Explore Travel",
  pricedAt:
    "*Offer applies with the purchase of any treatment booked and attended.",
  buttonMainText: "BOOK NOW",
  buttonSubtext: "DON'T MISS OUT!",
  offerInfo: "For holidays with minimum value of £2000. ",
  offerEndTime: "",
};
