export const schedule = {
  title: "WORKING HOURS",
  subTitle: "By appointment only",
  list: [
    {
      day: "Monday",
      schedule: "10.30 am - 8.00 pm",
    },
    {
      day: "Tuesday",
      schedule: "10.30 am - 8.00 pm",
    },
    {
      day: "Wednesday",
      schedule: "10.30 am - 8.00 pm",
    },
    {
      day: "Thursday",
      schedule: "10.30 am - 8.00 pm",
    },
    {
      day: "Friday",
      schedule: "10.30 am - 7.30 pm",
    },
    {
      day: "Saturday",
      schedule: "11.00 am - 7.00 pm",
    },
    {
      day: "Sunday",
      schedule: "11.00 am - 7.00 pm",
    },
  ],
};
